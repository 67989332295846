h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, p {
    color: #282828;
}
* {
    font-family: 'Mulish', sans-serif;
    scroll-behavior: smooth;
}
.js-scroll {
    opacity: 0;
    transition: opacity 500ms;
}
.js-scroll.scrolled {
    opacity: 1;
}
.scrolled.fade-in {
    animation: fade-in 1s ease-in-out both;
}
.scrolled.fade-in-bottom {
    animation: fade-in-bottom 1s ease-in-out both;
}
.scrolled.slide-left {
    animation: slide-in-left 1s ease-in-out both;
}
.scrolled.slide-right {
    animation: slide-in-right 1s ease-in-out both;
}
@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
        opacity: 0;
   }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
   }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        opacity: 0;
   }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
   }
}
@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
   }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
   }
}
@keyframes fade-in {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}
@media (min-width: 1200px) {
    .container {
        width: 1200px !important;
   }
}
button {
    background: #DC4D04;
    border: 1px solid #DC4D04;
    border-radius: 4px!important;
    box-shadow: none !important;
    outline: 0px !important;
    border-radius: 4px;
    padding: 10px 32px !important;
    font-size: 0.9 !important;
    color: #FFFFFF;
}
button img {
    height: 11px;
    margin-left: 10px;
}
input, select, textarea {
    box-shadow: none !important;
    outline: 0px !important;
}
.form-select:focus {
    border-color: #DC4D04!important;
}
/* Product Card */
.main-container {
    position: relative;
}
.p-container {
    width:100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
/* .p-container:after {
    content: '';
    position: absolute;
    right: 0px;
    height: 100%;
    width:10%;
    background: linear-gradient(90deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
}
.p-container::-webkit-scrollbar {
    display: none;
}
*/
.p-container .link {
    text-decoration: none;
}
.product-card {
    width: 275px;
    overflow: hidden;
    margin-right: 15px;
    margin-bottom: 50px;
    flex: 0 0 auto;
    cursor: pointer;
    background-color: rgba(205, 219, 227, 0.1);
    border-radius: 8px;
}
.product-card:hover {
    background-color: #FFFFFF;
    box-shadow: 0 10px 40px rgb(0 0 0 / 8%);
}
.product-card:hover .product-card-info .product-name {
    text-decoration: underline;
}
.product-card .product-card-img {
    margin-bottom: 10px;
    position: relative;
}
.product-card .product-card-img .badge {
    position: absolute;
    top:15px;
    left:15px;
    border-radius: 10px;
    font-weight: 400;
    background-color: #1C9B5E!important;
    padding: 5px 10px!important;
}
.product-card .product-card-img img {
    height: 200px;
    border-radius: 6px 6px 0 0;
}
.product-card .product-card-info {
    padding: 0 15px;
}
.product-card .product-card-info .product-name {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0 0 10px;
}
.product-card .product-card-info .product-price {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0 0 10px;
}
.product-card .product-card-info .product-price .p-currency {
    color: #5C5C5C;
    font-weight: 300;
}
.product-card .product-card-info .product-price .p-unit {
    font-size: 14px;
    color: #5C5C5C;
    font-weight: 300;
}
.product-card .product-card-info .product-spec-sum {
    font-size: 13.5px;
    margin: 0;
    padding: 0 0 10px;
}
.product-card .product-card-info .product-spec-sum span {
    color: #5C5C5C;
}
.product-card .product-card-info .product-link {
    font-size: 14px;
    color: #DC4D04;
}
/* End of Product Card */
/* Paginantion */
.pagination .page-item {
    margin:0 10px;
}
.pagination .page-item .page-link{
    border-radius: 4px!important;
    border:none!important;
    background-color: transparent;
    font-size: .9rem;
    font-weight: 500;
    color: #7F8D96;
}
.pagination .page-item .page-link:hover {
    color:#0083D3;
}
.pagination .page-item.active .page-link {
    background-color: #0083D3;
    color: #FFFFFF;
}
/* Media Queries */
@media (max-width: 992px) {
    #lp-what-we-do .lp-map {
        display: none;
   }
    br {
        display: none;
   }
    #goals h2, #goals .h2 {
        font-size: 1rem;
   }
    footer .footer-wrap {
        padding: 50px 0;
   }
    footer .footer-content div {
        margin-bottom: 45px;
   }
    #goals {
        padding: 50px 0;
   }
    #goals .vision {
        padding: 40px 0 0 0;
   }
    #goals .mission {
        padding-top: 0;
   }
    #goals .line {
        border-right: none;
        border-bottom: 0.2px solid #5c5c5c;
   }
    .navbar-nav .nav-item .nav-link {
        margin: 0;
   }
    #sourcepro-finance .cb-img {
        margin-top: 30px;
        float: left;
   }
    #sourcepro-finance .sp-img {
        float: left;
   }
    #sourcepro-finance .tf-img {
        float: left;
   }
    #traction-wrap .padding-left {
        padding-left: 0px;
   }
    #intel-top {
        min-height: auto;
   }
    #intel-top .intell-img {
        display: none;
   }
    #comm-data .commodity-info p {
        padding-right: 0;
   }
    #comm-data svg {
        float: left;
   }
}
@media (max-width: 768px) {
    br {
        display: none !important;
   }
    .navbar-nav .nav-item .nav-link {
        margin: 0;
   }
    .container {
        padding-left: 20px;
        padding-right: 20px;
   }
    .navbar-brand img {
        height: 45px;
   }
    #testimonial {
        padding: 50px 0;
   }
    #testimonial h2, #testimonial .h2 {
        padding: 0;
   }
    #testimonial .testimonial-box {
        width: 100%;
        margin: 25px 0 0;
   }
    #partners {
        padding: 50px 0;
   }
    #partners img {
        height: 25px;
        margin-bottom: 20px;
        margin-right: 30px;
   }
    footer .footer-wrap {
        padding: 50px 0;
   }
    footer .footer-content div {
        margin-bottom: 45px;
   }
    
    #solution-wrap-1 {
        padding: 50px 0;
   }
    #solution-wrap-1 h2, #solution-wrap-1 .h2 {
        font-size: 1.5rem;
        line-height: 35px;
   }
    #what-we-do-top {
        padding: 75px 0;
   }
    #what-we-do-top h2, #what-we-do-top .h2 {
        font-size: 2rem;
   }
    #sourcepro-finance {
        padding: 50px 0;
   }
    #sourcepro-finance h2, #sourcepro-finance .h2 {
        font-size: 1.5rem;
        line-height: 35px;
   }
    #sourcepro-finance .row-margin {
        margin-bottom: 50px;
   }
    #sourcepro-finance .margin-top {
        margin-top: 50px;
   }
    #sourcepro-finance .cb-img {
        margin-top: 30px;
        float: left;
   }
    #sourcepro-finance .sp-img {
        float: left;
   }
    #sourcepro-finance .tf-img {
        float: left;
   }
    #tf-feature {
        padding: 50px 0;
   }
    #tofa-tribe {
        padding: 75px 0;
   }
    #tofa-tribe h2, #tofa-tribe .h2 {
        font-size: 2.5rem;
   }
    #intel-top {
        min-height: auto;
   }
    #intel-top h2, #intel-top .h2 {
        margin-top: 0;
   }
    #intel-top .intell-img {
        display: none;
   }
    #comm-data .commodity-info p {
        padding-right: 0;
   }
    #comm-data svg {
        float: left;
   }
}
