.seller-setting-form {
  margin: 0;
  padding: 10px 25px 0;
}

.seller-setting-form h2 {
  font-size: 1rem;
}

.seller-setting-form h2 span {
  float:right;
  font-weight: normal;
  font-size: 14px;
}


.seller-setting-form form {
  width: 70%;
}

.seller-setting-formgroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
}
.form-group-right,
.form-group-left {
  padding: 0 10px;
}
.seller-setting-form .form-group {
  margin: 20px 0;
}
.seller-setting-form input {
  padding: 12px 15px;
  font-size: .85rem;
  color: #282828;
}
.seller-setting-form textarea {
  padding: 10px 15px;
}
.password-input {
  padding: 4px 5px!important;
  font-size: .1rem;
  font-size: 1rem;
}

.changepassword {
  width: 100%;
  margin-right: 20px;
  background: #FFFFFF !important;
  border:1px solid #dd4f05!important;
  color: #dd4f05!important;
  border: none;
  font-size: .9rem;
}

.modal-padding {
  padding: 0 30px;
}

.user-setting-change-pwd {
  font-size: .85rem!important;
  color: #0083d3!important;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
}

.seller-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.seller-seting-submit,
.seller-seting-joindate {
  width: 50%;
}

.savechanges {
  border: 1px solid !important;
  color: #ffffff !important;
  background-color: #dd4f05 !important;
  font-size: .9rem;
}
.address {
  height: 54px;
}

.seller-seting-joindate p {
  font-weight: 700;
  color: #1b2631;
  font-size: 14px;
  line-height: 18px;
  margin: 20px 0px 0px 50px;
}
.seller-seting-joindate {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .seller-setting-formgroup {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }
  .seller-footer {
    display: grid;
    grid-gap: 20px;
  }
  .seller-seting-joindate p {
    margin: 0 10px;
  }
  .seller-seting-submit button {
    margin: 10px;
  }
}
