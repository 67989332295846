.navbar-toggler-icon {
    width: 2rem;
}

.navbar-toggler {
    padding: 0 !important;
    color: #282828 !important;
    border: none; }
  
.main-nav {
    padding: 10px 0;
    background-color: transparent !important; }

.navbar-nav .nav-item {
font-size: .85rem; }

.navbar-nav .nav-item .nav-link {
    color: #282828;
    padding: .8rem .8rem;
    /* margin-left: 15px; */
    border-bottom: 2px solid transparent; 
}

.navbar-nav .nav-item:last-child {
    padding-right: 0;
}

.navbar-nav .main-nav-link {
    position:relative;
}

.navbar-nav .main-nav-link .notify {
    height: 9px;
    width: 9px;
    border-radius:50%;
    background-color: #DC4D04;
    position: absolute;
    left:21px;
}

.navbar-nav .main-nav-link .dropdown-icon {
    width: 12px;
    margin-left: 7px;
}

.navbar-nav .main-nav-link .msgs {
    /* height: 18px;
    width: 18px; */
    padding: 1px 6px;
    border-radius:50%;
    background-color: #DC4D04;
    position: absolute;
    left:10px;
    top: 5px;
    color: #FFFFFF;
    font-size: 11px;
}

/* .navbar-nav .main-nav-link:hover {
    color: #DC4D04 !important; }

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link.active {
    color: #DC4D04; }

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
    color: #DC4D04; } */

.navbar-brand img {
    height: 50px; }

.nav-btn {
    background: #DC4D04!important;
    border: 1px solid #DC4D04!important;
    border: 1px solid #DC4D04!important;
    border-radius: 4px;
    padding: 9px 28px !important;
    font-size: 1rem;
    color: #FFFFFF!important;
    font-size: 0.85rem !important;
    border-radius: 4px;
    box-shadow: none !important;
    outline: 0px !important;
    margin-left: 1.2rem;
    margin-top: .3rem; 
}

.nav-btn-sec {
    background: #FFFFFF!important;
    border: 1px solid #DC4D04!important;
    border: 1px solid !important;
    border-radius: 4px;
    padding: 9px 28px !important;
    font-size: 1rem;
    color: #DC4D04 !important;
    font-size: 0.85rem !important;
    border-radius: 4px;
    box-shadow: none !important;
    outline: 0px !important;
    margin-left: 1.2rem; 
    margin-top: .4rem; 
}

.nav-btn:hover {
    background: #F05305;
    border: 1px solid #F05305;
    color: #FFFFFF !important; }

.nav-btn-sec:hover {
    background: rgba(240, 83, 5, 0.1) !important;
    border: 1px solid rgba(240, 83, 5, 0.1) !important;
    color: #DC4D04 !important; 
}

/* NAvbar dropdown */

.main-nav .navbar-nav .dropdown-menu {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 40px rgb(0 0 0 / 20%);
    list-style: none;
    padding: 1.5rem 0 1.5rem;
    z-index: 2;
    transform: translateY(8%);
    border:none;
    top: 42px;
}

.main-nav .navbar-nav .dropdown-menu li {
    font-size: .9rem;
    margin-bottom: 15px;
}

.main-nav .navbar-nav .dropdown-menu li:last-child {
    margin-bottom: 0;
}

.main-nav .navbar-nav .dropdown-menu li a{
    padding-left: 20px;
    padding-right: 50px;
}

.main-nav .navbar-nav .dropdown-menu .dropdown-item .nav-icon{
    margin-right: 10px;
}

.main-nav .navbar-nav .dropdown-menu .dropdown-item:hover {
    background: #f9f9f9;
}

.main-nav .navbar-nav .dropdown-menu .dropdown-item span:hover {
    color:initial;
}

.main-nav .navbar-nav .dropdown-menu li span{
    font-size: .8rem;
    color: #282828;
    font-weight: 400;
}

/* End of navbar dropdown */


/* .dropdown:hover .dropdown-menu{
    display: block;
}
.dropdown-menu{
    margin-top: 0;
} */

/*Dropdown menu animation*/

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@media (min-width: 768px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

/*End Dropdown menu animation*/