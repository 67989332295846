.internet-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  text-align: center;
}

.internet-ctn button {
  color: #fff;
  margin-top: 10rem;
}

@media (max-width: 500px) {
  .internet-ctn h1 {
    font-size: 20px;
  }
}
