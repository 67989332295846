#product-details {
  padding: 50px 0 20px;
}

#product-details .back-text {
  font-size: 0.9rem;
  color: #5c5c5c;
}

#product-details .back-text a {
  color: #5c5c5c;
  text-decoration: none;
}

#product-details .back-text a:hover {
  color: #DC4D04;
}

#product-details .back-icon {
  margin-right: 15px;
}

.mp-wrap .main-product-pic {
  width: 90%;
  height: 350px;
  background-color: #f9f9f9;
  margin-bottom: 6px;
}

.mp-wrap .product-thumbnail {
  width: 90%;
}

.mp-wrap .product-thumbnail .pt-box {
  width: 80px;
  height: 75px;
  background-color: #f9f9f9;
  margin-right: 4px;
  margin-bottom: 4px;
}

.mp-wrap .product-thumbnail .pt-box:active {
  border: 2px solid #dc4d04;
}

.main-product-details .m-product-name {
  font-size: 1.6rem;
  margin: 0;
  padding: 0 0 10px;
}

.main-product-details .m-product-price {
  font-size: 1.3rem;
  margin: 0;
  padding: 0 0 20px;
}

.main-product-details .m-product-price .mp-currency {
  color: #5c5c5c;
  font-weight: 300;
}

.main-product-details .m-product-price .mp-unit {
  font-size: 1rem;
}

.main-product-details .m-product-info-wrap .m-product-info {
  font-size: 0.9rem;
  margin: 0 25px 0 0;
  padding: 0;
}

.main-product-details .m-product-info-wrap .m-product-info span {
  color: #5c5c5c;
}

/* Specification Table */

.m-product-spec-box {
  margin: 25px 0;
}

.m-product-spec-box .m-product-spec-title {
  font-size: 1rem;
  margin: 0;
  padding: 0 0 15px;
}

.m-product-spec-box .m-product-spec-title2 {
  font-size: 1.2rem;
  margin: 0;
  padding: 0 0 15px;
}

.m-product-spec-box .spec-link {
  font-size: 0.8rem;
  color: #dc4d04;
  float: right;
  margin: 0;
  padding: 0 0 0;
}

.m-product-spec-box .mp-desc {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 0 15px;
}

.m-product-spec-box table {
  width: 100%;
  font-size: 0.85rem;
  margin-bottom: 0;
}

.m-product-spec-box table tr {
  border-color: transparent !important;
}

.m-product-spec-box table tr td {
  padding: 12px 15px;
}

.m-product-spec-box table tr .mps-title {
  color: #5c5c5c!important;
}

.m-product-spec-box .cls-1 {
  fill: #ffffff;
  stroke: #282828;
  stroke-linecap: round;
}

#product-description {
  padding: 50px 0;
  background-color: #f9f9f9;
}

/* End of Specification Table */

.main-product-details .m-product-cta button {
  margin-right: 25px;
  font-size: 0.85rem;
  color: #FFFFFF;
}

.main-product-details .m-product-cta .inquiry-cta {
  background-color: #ffffff;
  color: #dc4d04;
}

/* Place Order Modal */

.place-order-modal .modal-header {
  border: none;
  padding: 30px 50px 10px;
}

.place-order-modal .modal-title {
  font-size: 1.2rem;
}

.place-order-modal .btn-close {
  padding: 0 !important;
  width: 0.8em !important;
}

.place-order-modal .modal-body {
  padding: 0 50px 50px;
}

.place-order-modal .modal-body .modal-sub-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0 0 15px;
}

.place-order-modal .modal-body form {
  width: 90%;
  margin: 20px 0 0;
}

.place-order-modal .modal-body form label {
  font-size: 0.85rem;
  font-weight: 400;
  margin: 0 0 10px;
}

.place-order-modal .modal-body form input {
  padding: 10px 15px;
  font-size: 0.85rem;
}

.place-order-modal .modal-body form select {
  padding: 10px 15px;
  font-size: 0.85rem;
  height: 42.39px;
}

.place-order-modal .modal-body form .custom-country-list {
  /* padding: 10px 15px; */
  font-size: 0.85rem;
  height: 42.39px!important;
}

.place-order-modal .modal-body form .custom-country-list .css-1s2u09g-control {
  border-color: #ced4da;
}

/* .place-order-modal .modal-body form .custom-country-list .css-1s2u09g-control:focus-within {
  border-color: red;
} */

.place-order-modal .modal-body form .custom-country-list input {
  padding: 3.5px 15px!important;
}

.place-order-modal .modal-body form textarea {
  padding: 10px 15px;
  font-size: 0.85rem;
}

.place-order-modal .modal-body form .custom-input {
  width: 100%;
  padding: 0 !important;
}

.place-order-modal .modal-body form .custom-input:focus-within {
  border-color: #DC4D04;
}

.place-order-modal .modal-body form .custom-input .form-unit {
  margin: 11px 0 11px 10px;
  padding: 0 15px;
  font-size: 0.8rem;
  border-left: 1px solid #dddddd;
}

.place-order-modal .modal-body form .custom-style {
  border: none;
}

.place-order-modal .modal-body form .modal-info {
  font-size: 0.8rem;
  color: #dc4d04;
  margin: 15px 0 10px;
  padding: 0;
}

.place-order-modal .modal-body .order-summary-front {
  margin: 20px 0;
  width: 100%;
}

.place-order-modal .modal-body button {
  font-size: 14px;
  color: #FFFFFF;
}

.order-summary-front .s-product-img {
  width: 65px;
}

.order-summary-front .s-product-name {
  font-size: 1.05rem;
  margin: 0;
  padding: 0 0 10px;
}

.order-summary-front .s-product-price {
  font-size: 0.9rem;
  margin: 0;
  padding: 0 0 20px;
}

.order-summary-front .s-product-price .s-mp-currency {
  color: #5c5c5c;
  font-weight: 300;
}

.order-summary-front .s-product-price .s-mp-unit {
  font-size: 0.7rem;
}

.order-summary-front .os-details {
  width: 100%;
}

.order-summary-front .os-details table {
  font-size: 0.85rem;
  margin-bottom: 0;
}

.order-summary-front .os-details table tr td {
  padding: 10px 15px;
}

.order-summary-front .os-details table tr .osd-title {
  color: #5c5c5c;
  padding-left: 0;
}

.order-summary-front .os-details .line {
  height: 1px;
  background-color: #dddddd;
  margin: 20px 0;
}

.modal-contact-info {
  margin-top: 50px;
}

.modal-contact-info .contact-head {
  font-size: 1rem;
  margin: 0 0 20px;
  padding: 0;
}

.modal-contact-info p {
  font-size: 0.85rem;
  margin: 0 0 10px;
  padding: 0;
}

.order-summary .os-details p {
  font-size: 1rem;
  margin: 0 0 10px;
  padding: 0;
}

.order-summary .os-details p span {
  color: #5c5c5c;
  margin-right: 50px;
}

/* Inquiry form */

#inquiry-form {
  padding: 50px 0;
  background-color: #ffffff;
}

.inq-form-wrap {
  border:1px solid #dddddd;
  padding: 20px 50px;
  border-radius: 10px;
}

form {
  width: 100%;
  margin: 20px 0;
  font-size: 0.85rem;
}

form label {
  font-size: 0.85rem;
  font-weight: 400;
  margin: 0 0 10px;
}

form .custom-country-list input {
  padding: 3px 15px!important;
}

form input {
  padding: 10px 15px ;
  font-size: 0.85rem ;
}

form select {
  padding: 10px 15px ;
  font-size: 0.85rem !important;
  height: 40.39px;
}

form textarea {
  padding: 10px 15px ;
  font-size: 0.85rem !important;
}

form .custom-input {
  width: 100%;
  padding: 0 !important;
}

form .custom-input:focus-within {
  border-color: #DC4D04;
}

form .custom-input .form-unit {
  margin: 11px 0 11px 10px;
  padding: 0 15px;
  font-size: 0.8rem;
  border-left: 1px solid #dddddd;
}

form .custom-style {
  border: none;
}

form button {
  color: #FFFFFF;
}

/* commodity insight */
svg .cls-1.selected {
  fill: #fb7837 !important;
  stroke: #fb7837;
}

@media (max-width: 768px) {
  .mp-wrap .main-product-pic {
    width: 100%;
  }

  .mp-wrap .product-thumbnail {
    width: 100%;
  }

  .mp-wrap .product-thumbnail .pt-box {
    width: 80px;
    height: 75px;
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: #f9f9f9;
  }

  .mp-wrap .product-thumbnail .pt-box:active {
    border: 2px solid #dc4d04;
  }

  .mp-wrap {
    margin-bottom: 40px;
  }

  .place-order-modal .modal-body form {
    width: 100% !important;
  }
}
