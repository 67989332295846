#partners {
  padding: 100px 0;
  background: #ffffff;
}
#partners h2,
#partners .h2 {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
  padding: 0 0 0;
}
#partners p {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 15px 0 0;
  line-height: 28px;
}
#partners img {
  margin-right: 50px;
  margin-bottom: 25px;
  height: 40px;
  object-fit: cover;
}

::-webkit-scrollbar {
  width: 1px;
  height: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(220, 218, 218);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(183, 171, 171);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cb9e9e;
}

.partner-ctn {
  width: 100%;
  /* overflow-x: scroll; */
  align-items: center;
  gap: 1rem;
}

#partners .partner-img {
  height: 100px;
}

.carousel-container {
  overflow: hidden !important;
  width: 100%;
}

.carousel-track {
  position: relative;
  width: 250%;
  display: flex;
  align-items: center;
  /* height: 80px; */
}

/* .carousel-track {
  display: flex;
  left: 0;
  position: absolute;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 200%;
  z-index: 40;
  top: 0;
  animation: slide 20s linear infinite;
} */

.carousel-card {
  float: left;
  width: 9.0909%; /* Each content div takes one-third of the container width */
  height: 100%; /* Set your desired height */
  box-sizing: border-box;
  text-align: center;
  animation: moveRightToLeft 25s linear infinite;
}

.carousel-card:hover {
  transform: scale(0.9);
}

.carousel-card .partner-img {
  height: 100px !important;
}

.carousel-track:hover {
  animation-play-state: paused;
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(330%);
  }
  100% {
    transform: translateX(
      -1100%
    ); /* Adjust based on the number of content divs */
  }
}

@media (max-width: 1020px) {
  .carousel-track {
    width: 620%;
  }
}
