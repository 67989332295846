/*Auth Page*/

.section-body {
  margin: 0 !important;
  padding: 0 !important;
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
  color: #8692a6;
  line-height: 0.1em;
  font-size: 0.95rem;
  margin: 10px 0 20px;
}

.divider span {
  background: #fff;
  padding: 0 35px;
}

.social-btns {
  margin-top: 50px;
}

.forgot-pwd {
  text-align: right;
  padding: 0;
  font-size: 0.95rem;
}

.forgot-pwd a {
  color: #282828;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

.forgot-pwd a:hover {
  color: #dd4f05;
}

@media (max-width: 768px) {
  .forgot-pwd {
    font-size: 0.8rem;
  }
}
