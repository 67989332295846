#b-features {
  padding: 70px 0;
  background: linear-gradient(0deg, #f5f9fe 0%, rgba(245, 249, 254, 0) 100%);
}

#b-features h2 {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
  padding: 0 0 0;
}

#b-features .f-subtitle {
  font-size: 0.9rem;
  font-weight: 300;
  margin: 0;
  padding: 10px 0 0;
  line-height: 26px;
}

#b-features h3 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 0 0 0;
}

#b-features p {
  font-size: 0.95rem;
  font-weight: 300;
  margin: 0;
  padding: 10px 0 0;
  line-height: 26px;
}

#b-features .b-feature-icon {
  height: 45px;
  margin: 50px 0 25px;
}

#b-trending {
  padding: 50px 0;
  background: #ffffff;
}

#b-trending h1 {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 40px;
}

#b-trending .subtitle {
  font-size: 0.9rem;
  padding: 0 0 35px;
  margin: 0;
  color: #282828;
}

#b-trending .subtitle .scroll-icon {
  width: 25px;
  margin-right: 15px;
}

/* Advert Space */

#ad-space {
  padding: 0 0 50px;
}

.feature-ad {
  background-image: url("../../assets/img/blue-g.png");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  border-radius: 6px;
  padding: 70px 50px;
}

.featured-ad-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feature-ad h2 {
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
  color: #ffffff;
}

.feature-ad p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: #ffffff;
}

.feature-ad button {
  font-size: 0.9rem;
  color: #ffffff;
}

.e-newsletter {
  width: 100%;
  border-radius: 0px;
  padding: 40px 30px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin: 0 0 50px;
}

.e-newsletter h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
}

.e-newsletter p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.e-newsletter input {
  font-size: 0.9rem;
  padding: 12px 15px;
}

.e-newsletter button {
  font-size: 0.9rem;
  color: #ffffff;
}

.scroll-icon {
  cursor: pointer;
}

.slide-container {
  width: 100%;
}

.slide-container .img-slider-ctn {
  width: 100%;
  max-height: 400px;
}

.slide-container .img-slider-ctn img {
  height: 100%;
  /* object-fit: contain; */
}

.default-nav {
  display: none !important;
}

@media (max-width: 768px) {
  .featured-ad-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .feature-ad button {
    margin-top: 25px;
  }

  .feature-ad {
    padding: 50px 30px;
  }

  .place-order-modal .modal-body {
    padding: 0 25px 25px !important;
  }

  .place-order-modal .modal-header {
    border: none;
    padding: 20px 25px 25px !important;
  }

  .slide-container .img-slider-ctn {
    width: 100%;
    height: 300px;
  }
}
@media (max-width: 400px) {
  .slide-container .img-slider-ctn {
    width: 100%;
    height: 200px;
  }
}
