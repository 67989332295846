::-webkit-scrollbar {
  width: 4px;
}

/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 grey;
  border-radius: 10px;
} */

::-webkit-scrollbar-thumb {
  background: rgb(173, 172, 172);
  border-radius: 2px;
  opacity: 0.2;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(173, 172, 172);
  opacity: 0.8;
}

/* .loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #dc4d04;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  justify-content: center;
  align-items: center;
  text-align: center;
} */

.login-btn {
  position: relative;
}
.loading {
  border: 4px solid #d9d7d7;
  border-top: 5px solid #dc4d04;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: calc(315% - 60px);
  left: calc(53% - 60px);
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0.6;
}
.gooey {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  /* filter: contrast(20); */
}
.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 12px;
  left: 15px;
  background: #dc4d04;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}
.dots > span {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-left: 16px;
  background: #dc4d04;
  border-radius: 50%;
}
@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

/* @keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.empty-state {
  text-align: center;
  align-items: center;
}

.empty-state > h3,
p {
  color: #282828;
}

@media (min-width: 904px) {
  .loading {
    top: calc(265% - 60px);
    left: calc(52% - 60px);
  }
}

@media (min-width: 994px) {
  .loading {
    top: calc(265% - 60px);
    left: calc(54% - 60px);
  }
}
