#about-us {
    padding: 80px 0 120px;
    background-image: url("../../assets/img/about-bg.png");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#about-us h2, #about-us .h2 {
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}
#about-us p {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 15px 30px 0 0;
    line-height: 30px;
}
#about-us h3, #about-us .h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 30px 0 40px;
    padding: 0 50px 0 30px;
    line-height: 32px;
    border-left: 3px solid #DC4D04;
}
#about-us img {
    margin-right: 10px;
}
#about-us .play-icon-text {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 0 !important;
    font-weight: 400;
}
#about-us .play-icon-text a {
    color: #282828;
    text-decoration: none;
}
#about-us .play-icon-text a:hover {
    color: #DC4D04;
    text-decoration: underline;
}
#goals {
    background: #282828;
    padding: 10px 0;
}
#goals .line {
    border-right: .2px solid #5c5c5c;
}
#goals .h-line {
    width: 40px;
    height: 1px;
    background: #DC4D04;
    float: left;
    margin-right: 20px;
    margin-top: 10px;
}
#goals .mission {
    padding: 40px 100px 40px 0;
}
#goals .vision {
    padding: 40px 0 0 70px;
}
#goals p {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    padding: 0 0 15px;
}
#goals h2, #goals .h2 {
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 32px;
    margin: 0;
    padding: 0;
}
#values {
    padding: 100px 0;
}
#values h2, #values .h2 {
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0;
    padding: 0 0 0;
}
#values p {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 15px 0 0;
    line-height: 28px;
}
/* #team-a {
    height: 400px;
    background-image: url("../../assets/img/team-a.png");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
*/
#team-a h2, #team-a .h2 {
    color: #FFFFFF;
    font-size: 3.5rem;
    margin: 0;
    padding: 0;
}
#l-team {
    padding: 100px 0;
    background: #FAFAFE;
}
#l-team h2, #l-team .h2 {
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0;
    padding: 0 0 0;
}
#l-team p {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 15px 0 0;
    line-height: 28px;
}
#l-team h3, #l-team .h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 30px 0 40px;
    padding: 0 50px 0 30px;
    line-height: 32px;
    border-left: 4px solid #DC4D04;
}
#l-team .l-member-wrap {
    margin: 0 40px 35px 0;
}
#l-team .l-member-wrap .l-member-img {
    width: 150px;
    height: 170px;
    margin-bottom: 10px;
    background: #DDDDDD;
    position: relative;
    overflow: hidden;
}
#l-team .l-member-wrap .l-member-img .team-img {
    width: 100%;
}
#l-team .l-member-wrap .l-member-img .linkedin-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}
#l-team .l-member-wrap h4, #l-team .l-member-wrap .h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
#l-team .l-member-wrap p {
    font-size: 12px;
    color: #5C5C5C;
    margin: 0;
    padding: 0;
}


@media (max-width: 768px) {

    #about-us {
        padding: 50px 0 50px;
    }
    #about-us h2, #about-us .h2 {
        font-size: 1.5rem;
    }
    #about-us p {
        padding-right: 0 !important;
    }
    #about-us h3, #about-us .h3 {
        padding-right: 0 !important;
        padding-left: 15px !important;
    }
    #goals {
        padding: 50px 0;
    }
    #goals .vision {
        padding: 40px 0 0 0;
    }
    #goals .mission {
        padding: 0;
    }
    #goals .line {
        border-right: none;
        border-bottom: 0.2px solid #5c5c5c;
    }
    #l-team {
        padding: 50px 0;
    }
    #l-team .l-member-wrap {
        margin: 0 10px 35px 0;
    }
    #l-team .l-member-wrap .l-member-img {
        width: 165px;
    }


}