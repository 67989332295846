.errorMessages {
  color: red;
  font-size: 15px;
}

.password-content h2 {
  font-size: 1.8rem;
  font-weight: 700;
}

.password-content {
  padding: 2rem 10rem;
}
.password-form {
  margin-top: 10px;
}

.password-form label {
  font-size: 0.95rem;
  font-weight: 400;
}

.password-form .input {
  height: 50px;
}

.password-form button {
  background: #dd4f05;
  border-color: #dd4f05;
  opacity: 1;
  margin-bottom: 35px;
  margin-top: 10px;
  color: #FFFFFF;
  font-weight: 500;
}

.password-form button:hover {
  background: #dd4f05;
  border-color: #dd4f05;
  color: #FFFFFF;
  opacity: 0.9;
}
.password-form {
  width: 100%;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
}
.error-message {
  color: #d50000;
  font-weight: 400;
  font-size: 12px;
}
@media (max-width: 768px) {
  .password-content {
    padding: 2rem;
    margin-top: 1rem;
  }
  .password-content div h3 {
    font-size: 1.2rem;
  }

  .button,
  .password-content div p {
    font-size: 0.8rem;
  }
}
