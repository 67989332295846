#main-hero {
  height: 80vh;
  background-image: url("../../assets/img/home.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#main-hero .row {
  height: 80vh;
  align-items: center;
}
#main-hero .hero-text h1,
#main-hero .hero-text .h1 {
  font-size: 3.8rem;
  margin: 0;
  padding: 15px 0 0;
  font-weight: 600;
  color: #ffffff;
}
#main-hero .hero-text h2,
#main-hero .hero-text .h2 {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
  padding: 40px 0 0;
}
#main-hero .hero-text p {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0 0 35px;
  padding: 15px 0 0;
  color: #ffffff;
}
#main-hero .hero-text p a {
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 400 !important;
}
#main-hero .hero-text p a img {
  height: 11px;
  margin-left: 10px;
}
#main-hero .hero-cta {
  background-color: #dc4d04;
  border-color: #dc4d04;
}
#lp-what-we-do {
  padding: 50px 0;
  /* background-color: rgba(221, 79, 5, 0.06); */
}
#lp-what-we-do h3,
#lp-what-we-do .h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 40px 0 0;
  padding: 0;
}
#lp-what-we-do h2,
#lp-what-we-do .h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 50px;
  margin: 0;
  padding: 50px 0 0;
}
#lp-what-we-do p {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 20px 0 0;
  line-height: 28px;
}
#lp-what-we-do p span {
  font-weight: 500;
}
#lp-what-we-do p a {
  color: #dc4d04;
  text-decoration: none;
  font-weight: 400 !important;
}
#lp-what-we-do p a img {
  height: 11px;
  margin-left: 10px;
}
#lp-what-we-do button {
  background: #dc4d04;
  border: 1px solid #dc4d04;
  margin-top: 30px;
}
#lp-what-we-do button:hover {
  background: #f05305;
  border: 1px solid #f05305;
  color: #ffffff !important;
}
#lp-what-we-do .lp-map {
  width: 450px;
}
#lp-our-services {
  padding: 40px 0;
  background-image: url("../../assets/img/h1-map1.svg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#lp-our-services .lp-service-info {
  padding-top: 100px;
  padding-left: 50px;
}
#lp-our-services .lp-service-info h2,
#lp-our-services .lp-service-info .h2 {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
  padding: 50px 0 0;
}
#lp-our-services .lp-service-info p {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding: 20px 0 0;
  line-height: 30px;
}
#lp-our-services .lp-service-info .line {
  width: 60px;
  height: 2px;
  background: #dc4d04;
  margin-top: 40px;
}
#lp-our-services .lp-img1 {
  width: 569px;
}
#lp-our-services .lp-img2 {
  width: 457px;
}
#lp-our-services .lp-img3 {
  width: 447px;
}
/* #lp-traction {
    background: #282828;
    padding: 50px 0;
}
#lp-traction .stats-box {
    text-align: center;
}
#lp-traction .stats-box h3, #lp-traction .stats-box .h3 {
    font-size: 22px;
    margin: 0 0 10px;
    padding: 0;
    color: #FFFFFF;
}
#lp-traction .stats-box h3 span, #lp-traction .stats-box .h3 span {
    font-size: 16px;
}
#lp-traction .stats-box p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 300;
    color: #FFFFFF;
}
#lp-traction .line {
    width: 1.5px;
    height: 60px;
    background: #DC4D04;
}
*/
#lp-product {
  padding: 100px 0;
  background: #f9f9f9;
}
#lp-product h2,
#lp-product .h2 {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
  padding: 0 0 0;
}
#lp-product p {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 20px 0 0;
  line-height: 28px;
}
#lp-product .product-box {
  position: relative;
  margin-bottom: 25px;
}
#lp-product .product-box img {
  width: 100%;
  opacity: 1;
  cursor: pointer;
}
#lp-product .product-box img:hover {
  opacity: 0.85;
}
#lp-product .product-box .title-bar {
  width: 100%;
  position: absolute;
  background: rgba(40, 40, 40, 0.7);
  padding: 15px 20px;
  bottom: 0;
}
#lp-product .product-box .title-bar h2,
#lp-product .product-box .title-bar .h2 {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.2rem;
}

/* .metric_heading {
  text-align: center;
}

.our_business {
  justify-content: center;
}

.our_business a {
  text-decoration: none !important;
}

.our_business .metric_title {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease-out;
  margin-top: 4rem;
}

.our_business .metric_title:hover {
  color: #dc4d04;
}

.metric_wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2.5rem;
  gap: 1rem;
}

.metric_wrapper .metric_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.metric_wrapper .metric_content .metric_icon {
  font-size: 26px;
  color: #f05305;
  width: 60px;
  height: 60px;
  border: 1px solid #f05305;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.metric_wrapper .metric_content h6 {
  font-weight: bold;
  font-size: 18px;
  margin: 0 !important;
}

.metric_wrapper .metric_content h6 .metric_unit {
  font-size: 12px;
  font-weight: 400;
  color: #dc4d04;
}

.metric_wrapper .metric_content p {
  padding: 0 !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  margin: 0 !important;
}

.metric_wrapper .metric_content p span {
  font-size: 12px;
  font-weight: 400;
  color: #dc4d04;
} */

.metric__link {
  text-decoration: none;
}

.metric__heading {
  font-size: 24px;
  margin-top: 20px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}

.metric__heading span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
}

.metric__heading .span-1 {
  background-color: #dc4d04;
}
.metric__heading .span-2 {
  background-color: #f5aa82;
}
.metric__heading .span-3 {
  background-color: #faea38;
}
.metric__heading .span-4 {
  background-color: #82b2f5;
}

.metric__heading:hover {
  color: #dc4d04;
}

.metri__container {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.metri__container .metric__icon img {
  width: 30px;
  height: auto;
}

/* .metri__container .metric_first {
  margin-top: 10px;
} */

.metri__container .metric__number {
  font-size: 20px;
  font-weight: bold;
}

.metri__container .metric__unit {
  font-size: 8px;
  color: #dc4d04;
}

.metri__container .metric__title {
  font-size: 13px;
  text-transform: uppercase;
}

.map__african__tofa {
  margin-top: 3rem;
}

.product-box .img-fluid {
  height: 250px;
}

@media (max-width: 768px) {
  #main-hero {
    height: 65vh;
  }
  #main-hero .row {
    height: 65vh;
    align-items: center;
  }
  #main-hero .hero-text h1,
  #main-hero .hero-text .h1 {
    font-size: 2.8rem;
  }
  #lp-what-we-do {
    padding: 50px 0;
  }
  #lp-what-we-do h2,
  #lp-what-we-do .h2 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 35px;
    margin: 0;
    padding: 0;
  }
  #lp-what-we-do .lp-map {
    display: none;
  }
  #lp-our-services .row {
    margin-bottom: 35px !important;
  }
  #lp-our-services .line {
    display: none;
  }
  #lp-our-services .lp-service-info {
    padding: 0;
  }
  #lp-our-services .lp-service-info h2,
  #lp-our-services .lp-service-info .h2 {
    font-size: 1.5rem;
    padding: 10px 0 0;
  }
  #lp-our-services .lp-service-info p {
    font-size: 16px;
    line-height: 28px;
  }
  #lp-our-services .lp-img {
    width: 100%;
    float: left;
  }
  #lp-product {
    padding: 50px 0;
  }
  #lp-product .product-box {
    margin-bottom: 25px;
  }
  .metri__sup__header {
    font-size: 20px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .metric__heading {
    font-size: 18px;
  }

  .metri__container .metric__icon {
    font-size: 18px;
  }

  .metri__container .metric_first {
    margin-top: 5px;
  }

  .metri__container .metric__number {
    font-size: 16px;
  }

  .metri__container .metric__title {
    font-size: 10px;
  }
  .metri__container .metric__unit {
    font-size: 6px;
  }
  .map__african__tofa {
    margin-top: 2rem;
  }
}
